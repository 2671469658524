@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Roboto:wght@300;400;500&display=swap');

html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Roboto', sans-serif;
  color: #302C37;
}

header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

option {
  font-weight: 300;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 50px;
  line-height: 60px;
  margin: 0;
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: white;
  margin: 0;
  font-weight: 400;
  margin-bottom: 15px;
}

h2.section-title {
  color: #302C37;
}

h3 {
  font-weight: 500;
  font-size: 35px;
  line-height: 60px;
  margin: 0;
}

p {
  margin: 0;
}

button {
  background-color: #DBFF00;
  padding: 10px 85px;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  border-radius: 5px;
  cursor: pointer;
  border: 0;
  outline: 0;
}

button:hover, button:active {
  background-color: #D0F107;
}

input, textarea {
  background-color: #3F3C45;
  border: 1px solid white;
  border-radius: 5px;
  padding: 12px;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

textarea {
  height: 195px;
}

b {
  color: #48257A;
}

li {
  font-size: 18px;
  color: #48257A;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
  font-weight: bold;
}

#get-trial {
  margin-top: 35px;
}

.text {
  font-size: 18px;
  line-height: 28px;
}

.light-text {
  color: white;
}

.section-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

*:focus-visible {
  outline: 0;
}

.horizontal-content {
  width: 1180px;
  margin: 0 auto;
}

.mobile-image {
  display: none;
}

#log-in, #mobile-log-in {
  font-size: 12px;
  color: #302C37;
  text-decoration: none;
  border: 1px solid #302C37;
  border-radius: 5px;
  padding: 10px 30px;
}
#log-in:hover {
  background: #F8F5FC;
}
#mobile-log-in {
  color: white;
  border: 1px solid white;
}

#language-strip {
  background: #302C37;
  height: 26px;
}
#language-strip .horizontal-content {
  display: flex;
  align-content: center;
  justify-content: flex-end;
}
#globe {
  margin-right: 4px;
}
#language-strip select {
  color: white;
  background: #302C37;
  border: 0;
  font-size: 10px;
  height: 26px;
  font-family: 'Roboto', sans-serif;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

#forms {
  background: #302C37;
}

#menu-strip {
  box-shadow: 0 1px 1px 0 #48257a;
}
#menu-strip .horizontal-content {
  height: 55px;
  display: flex;
  align-items: center;
}
#menu-strip .horizontal-content .box {
  flex: 1;
  display: flex;
  justify-content: center;
}
#menu-strip .horizontal-content .mobile-box {
  display: none;
}
#menu-strip .horizontal-content .box:first-child > img {
  margin-right: auto;
  height: 23px;
}
#menu-strip .horizontal-content .box:last-child > a {
  margin-left: auto;
}
#menu a {
  font-size: 14px;
  color: #302C37;
  margin: 0 17px;
  text-decoration: none;
}
.menu-item.active {
  border-bottom: 1px solid #302C37;
  padding-bottom: 5px;
}

#mobile-menu {
  display: none;
}
.hidden {
  display: none;
}

#home-background {
  background-color: #48257A;
  border-bottom-left-radius: 110px;
}
#home-title {
  padding-top: 100px;
  padding-bottom: 30px;
  color: white;
  width: 515px;
}
#home {
  position: relative;
  min-height: 700px;
  margin-bottom: -50px;
}
#home .text {
  width: 515px;
  margin-bottom: 25px;
}
#home > .horizontal-content {
  margin-top: 30px;
}
#hero {
  position: absolute;
  top: 0;
  height: 700px;
  right: calc((100% - 1180px) / 2 - 71px);
}
#hero-mobile {
  display: none;
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section .image {
  max-width: 530px;
  width: 100%;
}
.section .content {
  max-width: 530px;
  width: 100%;
}

.section p:nth-child(3) {
  margin-top: 8px;
}

#about-header {
  background-color: #48257A;
  border-top-left-radius: 110px;
  padding: 95px 0 85px;
  text-align: center;
}
#about-header .text {
  margin-top: 20px;
  margin-bottom: 55px;
}

#process {
  padding-top: 120px;
  padding-bottom: 120px;
}
#process .horizontal-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#process h2 {
  color: #302C37;
  margin-bottom: 30px;
}
#process h3 {
  line-height: 40px;
  margin-bottom: 15px;
}
#process .process-section:not(:last-child) .text {
  margin-bottom: 40px;
}
#process-info {
  width: 680px;
}
.process-image {
  width: 400px;
}

#forms .horizontal-content {
  padding-bottom: 40px;
  text-align: center;
}
#forms h2 {
  margin-bottom: 10px;
}
#forms h2 > span {
  border-bottom: 5px solid #DBFF00;
}
#forms .light-text {
  margin-top: 25px;
  margin-bottom: 70px;
}
#forms form {
  color: white;
  text-align: left;
  width: 830px;
  margin: 0 auto;
}
#forms label {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}
#forms button {
  font-size: 14px;
  padding: 10px 30px;
}
#logo-bottom {
  height: 26px;
  margin-top: 140px;
}

footer {
  padding-top: 50px;
  padding-bottom: 40px;
}
#contacts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 14px;
  font-size: 14px;
}
#facebook-contact-mobile {
  display: none;
}
#copyright {
  font-size: 12px;
  font-weight: 300;
  margin-top: 30px;
}

#register {
  padding-top: 120px;
}

#contact {
  padding-top: 80px;
}

.form-separator {
  height: 1px;
  background-color: white;
  margin-top: 60px;
}

form {
  position: relative;
}
.form-line {
  padding-bottom: 25px;
  position: relative;
}
.form-line-double {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.error-message {
  color: #FF6B6B;
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  margin-top: 4px;
  display: none;
}
.error-message.visible {
  display: block;
}
#message + .error-message {
  margin-top: 0;
}
.with-error {
  border-color: #FF6B6B;
}
.input {
  width: calc(50% - 11px);
}
.input input {
  width: calc(100% - 26px);
}
.subm {
  margin-top: 10px;
  text-align: right;
}
#submit-failed, #register-submit-failed {
  display: none;
}
#submit-failed.visible {
  display: block;
  color: #FF6B6B;
  right: 0;
  margin-top: 10px;
  position: absolute;
}
#submit-success {
  display: none;
}
#submit-success.visible {
  display: block;
  right: 0;
  margin-top: 10px;
  position: absolute;
}
#name, #message {
  width: calc(100% - 26px);
}
#dec {
  position: fixed;
  right: -2000px;
}

@media screen and (max-width: 1400px){
  #home-background, #about-header {
    border-radius: 0;
  }
}

@media screen and (max-width: 1210px){
  body {
    text-align: center;
  }

  .section {
    display: block;
    margin-bottom: 50px;
  }
  .section .content {
    width: calc(100% - 60px);
    margin: 0 auto;
  }
  .section-text {
    font-size: 14px;
  }

  h1 {
    font-size: 40px;
    line-height: 45px;
  }

  h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 25px;
  }

  h3 {
    font-size: 20px;
    line-height: 25px;
  }

  ul {
    text-align: left;
    display: inline-block;
  }

  .text {
    font-size: 18px;
  }

  .horizontal-content {
    width: calc(100% - 60px);
    margin: 0 auto;
    max-width: 530px;
  }
  #menu-strip .horizontal-content {
    max-width: unset;
  }

  button {
    width: 100%;
  }

  .mobile-image {
    display: inline;
  }
  .right-image {
    display: none;
  }

  #language-strip, #menu {
    display: none;
  }
  #menu-strip .horizontal-content .login-box {
    display: none;
  }
  #menu-strip .horizontal-content .mobile-box {
    display: block;
    justify-content: right;
    text-align: right;
  }

  #burger, #close {
    cursor: pointer;
  }

  #mobile-menu.visible {
    display: block;
    position: fixed;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #2E1F44;
    opacity: 0.9;
    z-index: 1;
    padding-top: 50px;
    overflow-y: auto;
  }
  .mobile-menu-item {
    display: block;
    margin: 0 auto 35px;
    font-size: 18px;
    color: white;
    text-decoration: none;
    width: fit-content;
  }
  .mobile-menu-item.active {
    border-bottom: 1px solid white;
    padding-bottom: 5px;
  }
  #mobile-language-selector {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  #mobile-language-selector select {
    color: white;
    background: transparent;
    border: 0;
    font-size: 14px;
    height: 26px;
    font-family: 'Roboto', sans-serif;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  #mobile-globe {
    height: 13px;
    margin-right: 4px;
  }

  #home-title {
    padding-top: 40px;
    padding-bottom: 0;
    width: auto;
  }
  #hero-mobile {
    display: block;
    width: calc(100% - 30px);
    margin-top: -4%;
    margin-bottom: -7%;
  }
  #hero {
    display: none;
  }
  #home .text {
    width: auto;
  }
  #home {
    margin-bottom: 10px;
  }
  #home-background {
    background-color: transparent;
    position: relative;
  }
  #home-mobile-background {
    background-color: #48257A;
    border-bottom-left-radius: 110px;
    position: absolute;
    height: 95%;
    left: 0;
    right: 0;
    z-index: -1;
  }

  #about {
    margin-bottom: 50px;
  }
  #about-header {
    border-top-left-radius: 110px;
    padding-top: 65px;
    padding-bottom: 60px;
  }

  #process .horizontal-content {
    display: block;
  }
  #process-info {
    width: auto;
  }
  .process-image {
    display: none;
  }
  #process .mobile-image {
    max-width: 530px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 85%;
    background-position: center;
  }
  #process .text {
    font-size: 16px;
  }
  #process {
    margin-bottom: 80px;
  }

  #forms .horizontal-content {
    padding-top: 80px;
  }
  #forms h2 {
    margin-bottom: 30px;
  }
  #forms .text {
    font-size: 16px;
  }
  #forms form {
    width: 100%;
  }
  #forms .form-line {
    display: block;
  }
  #forms form .input {
    width: 100%;
  }
  .input:first-child {
    margin-bottom: 25px;
  }
  .subm button {
    width: auto;
  }

  #contacts {
    display: block;
  }
  #facebook-contact {
    display: none;
  }
  #facebook-contact-mobile {
    display: block;
    margin: 0 auto 10px;
  }
  #email-contact {
    margin-bottom: 35px;
  }

}

@media screen and (max-width: 440px){
  #home-mobile-background {
    border-bottom-left-radius: 60px;
  }
}

@media screen and (max-width: 400px){
  #home-mobile-background {
    height: 100%;
  }
}
